<template>
  <main id="website-page" :class="{comparing: compareChartIsActive}">
    <!-- Visitor data -->
    <visitors-chart class="tour-step-1" @toggle="comparisonToggled"/>
    <visitors-donut v-if="!compareChartIsActive"/>

    <!-- Historical data and top -->
    <info-list class="tour-step-2"/>
    <referrals/>
    <popular-pages class="tour-step-4"/>

    <!-- For freemium -->
    <breakdown-list class="tour-step-5"/>
    <benchmarks class="tour-step-6"/>

    <!-- Audience: Genders, Ages and returning vs new -->
    <audience/>

    <!-- Technical details -->
    <technical-details/>

    <!-- Geographic data -->
    <google-map/>
    <cities class="tour-step-3"/>
  </main>
</template>

<script>

  import lazyLoadComponent from "@/utils/lazy-load-component";
  import SkeletonBox from "@/app/shared/components/SkeletonBox";

  export default {
    name: "Website",
    components: {
      Cities: lazyLoadComponent({
        componentFactory: () => import("@/app/website-dev/components/Cities"),
        loading: SkeletonBox
      }),
      GoogleMap: lazyLoadComponent({
        componentFactory: () => import("@/app/website-dev/components/Map"),
        loading: SkeletonBox
      }),
      TechnicalDetails: lazyLoadComponent({
        componentFactory: () => import("@/app/website-dev/components/TechnicalDetails"),
        loading: SkeletonBox
      }),
      Audience: lazyLoadComponent({
        componentFactory: () => import("@/app/website-dev/components/Audience"),
        loading: SkeletonBox
      }),
      Benchmarks: lazyLoadComponent({
        componentFactory: () => import("@/app/website-dev/components/Benchmarks"),
        loading: SkeletonBox
      }),
      BreakdownList: lazyLoadComponent({
        componentFactory: () => import("@/app/website-dev/components/BreakdownList"),
        loading: SkeletonBox
      }),
      PopularPages: lazyLoadComponent({
        componentFactory: () => import("@/app/website-dev/components/PopularPages"),
        loading: SkeletonBox
      }),
      Referrals: lazyLoadComponent({
        componentFactory: () => import("@/app/website-dev/components/Referrals"),
        loading: SkeletonBox
      }),
      InfoList: lazyLoadComponent({
        componentFactory: () => import("@/app/website-dev/components/InfoList"),
        loading: SkeletonBox
      }),
      VisitorsDonut: lazyLoadComponent({
        componentFactory: () => import("@/app/website-dev/components/VisitorsDonut"),
        loading: SkeletonBox
      }),
      VisitorsChart: lazyLoadComponent({
        componentFactory: () => import("@/app/website-dev/components/VisitorsChart"),
        loading: SkeletonBox
      })
    },
    data: () => ({
      compareChartIsActive: false
    }),
    methods: {
      comparisonToggled(value) {
        this.compareChartIsActive = value
      }
    }
  }
</script>

<style lang="scss">
  #website-page {
    padding: 0 15px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-areas:
      "visitors-chart visitors-chart visitors-donut"
      "historical-data referrals popular-pages"
      "breakdown-list breakdown-list breakdown-list"
      "benchmarks benchmarks benchmarks"
      "audience audience audience"
      "technical-details technical-details technical-details"
      "map map cities";
    gap: 30px;


    &.comparing {
      grid-template-areas:
      "visitors-chart visitors-chart visitors-chart"
      "historical-data referrals popular-pages"
      "breakdown-list breakdown-list breakdown-list"
      "benchmarks benchmarks benchmarks"
      "audience audience audience"
      "technical-details technical-details technical-details"
      "map map cities";
    }

    .card {
      margin-bottom: 0;
      min-height: 100%;
    }

    #visitors-chart {
      grid-area: visitors-chart;
    }

    #visitors-donut {
      grid-area: visitors-donut;
    }

    #historical-data {
      grid-area: historical-data;
    }

    #referrals {
      grid-area: referrals;
    }

    #popular-pages {
      grid-area: popular-pages;
    }

    #breakdown-list {
      grid-area: breakdown-list;
    }

    #benchmarks {
      grid-area: benchmarks;
    }

    #audience {
      grid-area: audience;
    }

    #technical-details {
      grid-area: technical-details;
    }

    #map {
      grid-area: map;
    }

    #cities {
      grid-area: cities;
    }
  }
</style>
